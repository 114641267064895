import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

import history from '../../helpers/history';
import routes from '../../helpers/routes.config';
import i18n from '../../helpers/i18n';

import alertsActions from '../../redux/actions/alerts.actions';
import axios from '../../helpers/axios';
import { twoFactorActions } from '../../redux/actions/twoFactor.actions';
import { TwoFactorModal } from '../../components/TwoFactorModal/TwoFactorModal';
import authActions from '../../redux/actions/auth.actions';

class App extends React.Component {
  twoFactorCallbacks = [];

  constructor(props) {
    super(props);
    const { clearAlerts } = props;

    history.listen(() => {
      clearAlerts();
    });

    this.state = {};
  }

  componentDidMount() {
    /*
     * Passing callback to axios interceptor so it can react when request is interrupted by 2FA
     * TODO: this could be moved to TwoFactorModal.jsx
     * */
    axios.onTwoFactorCallback = (requestData, successCallback, errorCallback, mode, is2faError) => {
      /*
       * Setup 2FA modal with request data, and opens it
       * (modal is fully controlled by TwoFactor store)
       * */
      this.onTwoFactor(requestData, successCallback, errorCallback, mode, is2faError);
    };
    axios.closeTwoFactor = () => {
      this.onCloseTwoFactor();
    };

    const { checkTokenStatus } = this.props;

    checkTokenStatus(['AUTH_USER_GET_PROFILE']);
  }

  /*
   * Handle 2FA detected by axios interceptor
   * */
  onTwoFactor(requestData, successCallback, errorCallback, mode, is2faError) {
    if (is2faError === '2FA_REQUIRED' || mode === 'initial') {
      this.twoFactorCallbacks = [];
    }
    /*
     * Preserving callbacks for 2FA resolution
     * */
    this.twoFactorCallbacks.push({ success: successCallback, error: errorCallback });
    /*
     * Passing request data to store, this action also opens TwoFactorModal
     * */
    const { openTwoFactor } = this.props;
    openTwoFactor(requestData, mode, is2faError);
  }

  onCloseTwoFactor() {
    const { closeTwoFactor } = this.props;
    closeTwoFactor();
  }

  /*
   * Pass response from repeated request with 2FA authorization to original promise
   * */
  onTwoFactorResolution(type, response) {
    if (type === 'success') {
      for (let i = this.twoFactorCallbacks.length; i > 0; i -= 1) {
        this.twoFactorCallbacks[i - 1].success(response);
      }
    } else {
      for (let i = this.twoFactorCallbacks.length; i > 0; i -= 1) {
        this.twoFactorCallbacks[i - 1].error(response);
      }
    }
  }

  renderRoutes() {
    return routes.map((route) => {
      switch (route.type) {
        case 'route': {
          const paths = [];
          const availableLocales = Object.keys(route.locales);
          availableLocales.forEach((value) => {
            const newRoute = route.omitLang
              ? `${route.locales['en'].path}`
              : `/${value}${route.locales[value].path}`;
            paths.push(newRoute);
          });

          return (
            <Route
              key={`${route.type}-${route.id}`}
              path={paths}
              exact={!!route.exact}
              component={route.component}
            />
          );
        }
        case 'redirect': {
          return (
            <Redirect
              key={`${route.type}-${route.id}`}
              from={route.from}
              to={`${i18n.language}${route.locales[i18n.language].to}`}
            />
          );
        }
        default:
          return '';
      }
    });
  }

  render() {
    return (
      <div className="app-wrapper h-100">
        <ToastContainer />

        <Router history={history}>
          <Switch>
            {this.renderRoutes()}
            <Redirect from="/" to={`/${i18n.language}`} />
          </Switch>
          <TwoFactorModal
            onResolution={(type, response) => this.onTwoFactorResolution(type, response)}
          />
        </Router>
      </div>
    );
  }
}

function mapState(state) {
  const { alerts } = state;
  return { alerts };
}

const actionCreators = {
  clearAlerts: alertsActions.clear,
  openTwoFactor: twoFactorActions.openTwoFactor,
  closeTwoFactor: twoFactorActions.closeTwoFactor,
  checkTokenStatus: authActions.checkTokenStatus,
};

const translatedApp = withTranslation()(App);
const connectedApp = connect(mapState, actionCreators)(translatedApp);

App.propTypes = {
  clearAlerts: PropTypes.func,
  checkTokenStatus: PropTypes.func,
};

export default connectedApp;
