import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomLink from '../../CustomLink/CutomLink';
import PasswordInput from '../../PasswordInput/PasswordInput';
import HelpInfo from '../../HelpInfo/HelpInfo';
import loginActions from '../../../redux/actions/login.actions';
import alertsActions from '../../../redux/actions/alerts.actions';
import redirectHelper from '../../../helpers/redirectHelper';
import { REQUEST_STATUS } from '../../../models/common.model';
import { getUrlParam } from '../../../helpers/history';

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });
  const [extendedSession, setExtendedSession] = useState(false);
  const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(false); // TODO: Remove when redirect is implemented

  const preLoginStatus = useSelector((state) => state.login.preLoginStatus);
  const loginStatus = useSelector((state) => state.login.loginStatus);
  const shortToken = useSelector((state) => state.login.shortToken);
  const loginErrors = useSelector((state) => state.login.errors);
  const history = useHistory();
  const preLoginError = preLoginStatus === REQUEST_STATUS.ERROR;
  const { search } = useLocation();
  const emailFromUrl = getUrlParam('email', search) || '';

  const onFormSubmit = (values) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA, { action: 'submit' })
        .then((token) => {
          dispatch(loginActions.preLogin(values.email, values.password, token));
        });
    });
  };

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email(t('validation.email'))
      .required(t('validation.required')),
    password: yup
      .string('Enter your password')
      .min(8, t('validation.passwordMin8'))
      .required(t('validation.required')),
  });

  const formik = useFormik({
    initialValues: {
      email: emailFromUrl,
      password: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onFormSubmit(values),
  });

  useEffect(() => {
    const documentId = new URLSearchParams(search).get('documentId');
    const certificateId = new URLSearchParams(search).get('certificateId');
    const workspaceId = new URLSearchParams(search).get('workspaceId');
    const redirectUrl = new URLSearchParams(search).get('rurl');
    let workspacePath = '';

    if (workspaceId) {
      workspacePath = `?workspaceId=${workspaceId}`;
    }

    if (documentId) {
      redirectHelper.setRedirect(
        `${process.env.REACT_APP_APP_URL}/document/${documentId}${workspacePath}`
      );
    }

    if (redirectUrl) {
      redirectHelper.setRedirect(redirectUrl);
    }

    if (certificateId) {
      redirectHelper.setRedirect(
        `${process.env.REACT_APP_APP_URL}/certificate/${certificateId}${workspacePath}`
      );
    }
  }, [search]);

  useEffect(() => {
    if (preLoginStatus === REQUEST_STATUS.SUCCESS && !alreadyLoggedIn) {
      dispatch(loginActions.login(shortToken, extendedSession));
    }
  }, [preLoginStatus, shortToken, dispatch, extendedSession, alreadyLoggedIn]);

  useEffect(() => {
    if (loginStatus === REQUEST_STATUS.SUCCESS) {
      /*
       * TODO: Create conditional redirect, remember to make some kind of whitelist before redirect!
       * */
      setAlreadyLoggedIn(true);
      // window.location.href = process.env.REACT_APP_APP_URL;
      redirectHelper.redirect(history);
    }
    if (loginStatus === REQUEST_STATUS.ERROR) {
      if (loginErrors.length > 0) {
        if (loginErrors[0].code === 'CAPTCHA_INVALID') {
          /*
           * TODO: change this to something better in future
           * */
          dispatch(alertsActions.error(t('captcha.captchaInvalid')));
        }
      }
    }
  }, [loginStatus, loginErrors, dispatch, t, history]);

  useEffect(() => {
    const passwordEl = document.getElementsByName('password')[0];
    if (emailFromUrl && passwordEl) {
      passwordEl.focus();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          {!isDesktopVersion ? (
            <Col xs={12} className="m-0 p-0 d-flex justify-content-between mb-6">
              <h1 className="h2 mb-0">{t('login.header')}</h1>
              <Button variant="link link-large" className="p-0 link">
                <CustomLink to={`/${t('urls.register')}`}>
                  {t('header.registerRedirectLinkText')}
                </CustomLink>
              </Button>
            </Col>
          ) : (
            <h1>{t('login.header')}</h1>
          )}
          <Form
            onSubmit={formik.handleSubmit}
            onChange={() => {
              if (preLoginError) {
                dispatch(loginActions.clearStatus());
              }
            }}
          >
            <Form.Group controlId="loginFormEmail">
              <Form.Control
                isInvalid={(formik.touched.email && !!formik.errors.email) || preLoginError}
                spellCheck={false}
                autoComplete="off"
                name="email"
                autoCapitalize="none"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder={t('login.emailPlaceholder')}
              />
              <Form.Control.Feedback
                className="font-weight-regular text text-body-small font-weight-medium"
                type="invalid"
              >
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="loginFormPassword">
              <PasswordInput
                isInvalid={(formik.touched.password && !!formik.errors.password) || preLoginError}
                placeholder={t('login.passwordPlaceholder')}
                name="password"
                value={formik.values.password}
                onChangeEvent={formik.handleChange}
                feedback={
                  preLoginError ? t('login.errors.invalidCredentials') : formik.errors.password
                }
              />
            </Form.Group>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId="loginFormExtended">
                  <Form.Check type="checkbox" className="no-select">
                    <Form.Check.Input
                      checked={extendedSession}
                      onChange={(event) => setExtendedSession(event.target.checked)}
                    />
                    <Form.Check.Label className="d-flex align-items-center">
                      <span>
                        <span className="ml-2">{t('login.remember')}</span>
                      </span>
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Col>
              {isDesktopVersion && (
                <Col xs={12} sm={6} className="text-center text-sm-right">
                  <Button variant="link" className="p-0 link link-light">
                    <CustomLink target="_blank" to={`/${t('urls.passwordLost')}`}>
                      {t('login.forgotPassword')}
                    </CustomLink>
                  </Button>
                </Col>
              )}
            </Row>
            <Button
              className="d-flex align-items-center justify-content-center mt-5 w-100 btn-medium shadow-none"
              variant={
                loginStatus === REQUEST_STATUS.SUCCESS || alreadyLoggedIn ? 'success' : 'primary'
              }
              type="submit"
              disabled={loginStatus === REQUEST_STATUS.SUCCESS || alreadyLoggedIn}
            >
              {t('login.button')}
              {(loginStatus === REQUEST_STATUS.SUCCESS || alreadyLoggedIn) && (
                <i className="ml-2 fico-doxy-success" />
              )}
            </Button>
            {!isDesktopVersion && (
              <Row>
                <Col xs={12} className="text-center">
                  <Button variant="link" className="link link-light pb-0 mt-4">
                    <CustomLink to={`/${t('urls.passwordLost')}`}>
                      {t('login.forgotPassword')}
                    </CustomLink>
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
        <Col
          xs={12}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
          className="mt-5 mb-5 mt-md-7 mb-md-7"
        >
          <HelpInfo label={t('support.info')} linkLabel={t('support.link')} />
        </Col>
      </Row>
    </>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
