import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import CustomLink from '../../CustomLink/CutomLink';
import links from '../../../helpers/mainLinks';
import i18n from '../../../helpers/i18n';

const RegisterForm = (props) => {
  const { t } = useTranslation();
  const { passedEmail, disabled } = props;
  const { language } = i18n;
  const mainLinks = links(language);
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });
  const [email, setEmail] = useState(passedEmail || '');
  const [emailError, setEmailError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const firstRender = useRef(true);
  let submitButtonRef = useRef(null);

  const validateEmail = (value) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(value);
  };

  const validateForm = useCallback(() => {
    let isValid = true;
    if ((email.length === 0 || !validateEmail(email)) && !passedEmail) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    if (!termsChecked) {
      isValid = false;
    }
    return isValid;
  }, [email, termsChecked, passedEmail]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (formSubmitted) {
      validateForm();
    }
  }, [validateForm, formSubmitted]);

  const onFormSubmit = (e) => {
    const { submitForm } = props;
    e.preventDefault();
    submitButtonRef.blur();
    setFormSubmitted(true);

    if (validateForm()) {
      submitForm(email);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          {!isDesktopVersion ? (
            <Col xs={12} className="m-0 p-0 d-flex justify-content-between mb-6">
              <h1 className="h2 mb-0">{t('register.header')}</h1>
              <Button variant="link link-large" className="pl-2 pt-0 pb-0 link">
                <CustomLink to="/">{t('header.loginRedirectLinkText')}</CustomLink>
              </Button>
            </Col>
          ) : (
            <h1>{t('register.header')}</h1>
          )}
          <Form onSubmit={onFormSubmit}>
            <Form.Group controlId="formRegisterEmail">
              <Form.Control
                isInvalid={emailError}
                spellCheck={false}
                autoComplete="off"
                autoCapitalize="none"
                value={email}
                onChange={(event) => setEmail(event.target.value.trim())}
                placeholder={t('register.emailPlaceholder')}
                disabled={!!passedEmail}
              />
              <Form.Control.Feedback className="font-weight-regular text-body-small" type="invalid">
                {t('register.emailError')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formRegisterTerms">
              <Form.Check type="checkbox" className="no-select">
                <Form.Check.Input
                  isInvalid={!termsChecked && formSubmitted}
                  checked={termsChecked}
                  onChange={(event) => setTermsChecked(event.target.checked)}
                />
                <Form.Check.Label className="d-flex align-items-center">
                  <span
                    className="ml-3"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: t('register.termsText', {
                        privacyPolicyUrl: mainLinks.privacyPolicy,
                        termsOfServiceUrl: mainLinks.termsOfService,
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Button
              className="mt-8 w-100 btn-medium shadow-none"
              variant="primary"
              type="submit"
              disabled={disabled}
              ref={(buttonRefDOM) => {
                submitButtonRef = buttonRefDOM;
              }}
            >
              {t('register.button')}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

RegisterForm.propTypes = {
  submitForm: PropTypes.func,
  passedEmail: PropTypes.string,
};

export default RegisterForm;
