import gaHelper from '../../helpers/gaHelper';
import identityConstants from '../constants/identity.constants';
import identityService from '../services/identity.service';

const identityActions = {
  getInvitationDetails,
  joinWorkspace,
  registerIntoWorkspace,
};

function getInvitationDetails(token) {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_INVITATION_DETAILS_REQUEST });
    identityService
      .getInvitationStatus(token)
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_INVITATION_DETAILS_SUCCESS,
          invitationDetails: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: identityConstants.FETCH_INVITATION_DETAILS_FAILURE });
      });
  };
}

function joinWorkspace(inviteId) {
  return (dispatch) => {
    dispatch({ type: identityConstants.JOIN_WORKSPACE_REQUEST });
    identityService
      .joinWorkspace(inviteId)
      .then((response) => {
        dispatch({ type: identityConstants.JOIN_WORKSPACE_SUCCESS, identityData: response.data });
      })
      .catch(() => {
        dispatch({ type: identityConstants.JOIN_WORKSPACE_FAILURE });
      });
  };
}

function registerIntoWorkspace(inviteId, language, captchaToken) {
  return (dispatch) => {
    dispatch({ type: identityConstants.REGISTER_INTO_WORKSPACE_REQUEST });
    identityService
      .registerIntoWorkspace(inviteId, language, captchaToken)
      .then((response) => {
        dispatch({
          type: identityConstants.REGISTER_INTO_WORKSPACE_SUCCESS,
          confirmCode: response.data.data.activationCode,
        });
        gaHelper.initialize();
        gaHelper.sendEvent('RegistrationStarted');
      })
      .catch((error) => {
        dispatch({
          type: identityConstants.REGISTER_INTO_WORKSPACE_FAILURE,
          error: error?.response?.data?.errors,
        });
      });
  };
}

export default identityActions;
