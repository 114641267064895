import axios from 'axios';
import i18n from './i18n';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const isTwoFactorError = (errors, config, errorCode) => {
  let is2FAError = false;
  let isForbidden = false;
  let isMaintenance = false;
  if (errors) {
    errors.forEach((error) => {
      if (error.code === 'TWO_FA_REQUIRED') {
        is2FAError = '2FA_REQUIRED';
      }
      if (error.code === 'FORBIDDEN') {
        isForbidden = true;
      }

      if (error.code === 'UNDER_MAINTENANCE_ERROR') {
        isMaintenance = true;
      }
    });
  }

  if (isMaintenance) {
    if (!config.overrideMaintenanceRedirect) {
      const mntPath = i18n.translator.translate('urls.maintenance');
      if (window.location.pathname !== `/${i18n.language}/${mntPath}`) {
        window.location.pathname = `${i18n.language}/${mntPath}`;
      }
    }
  }

  if (config.twoFactorSigned && errorCode === 403 && isForbidden) {
    is2FAError = '2FA_CODE_ERROR';
  }
  return is2FAError;
};

const prepare2FAPromise = (config) => {
  return new Promise((resolve, reject) => {
    /*
     * prepare callbacks to resolve configs
     * callbacks will be stored in App.js
     * */
    const successCallback = (response) => {
      /*
       * We got code from user, lets try it
       * */
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          'Two-Factor-Code': response.twoFactorCode,
          'Two-Factor-Token': response.twoFactorToken,
        },
        twoFactorSigned: true,
      };
      resolve(newConfig);
    };
    const errorCallback = (response) => {
      /*
       * For some reason 2FA flow has been interrupted, pass default configs
       * */
      if (response) {
        reject(response);
      }
      reject(config);
      instance.closeTwoFactor();
    };
    /*
     * call callback from App.js to initialize 2FA modal and pass data to store
     * */
    instance.onTwoFactorCallback(
      config, // original request config
      successCallback, // callback when 2FA is resolved successfully
      errorCallback, // callback when user failed to authorize request with 2FA
      'initial',
      '2FA_REQUIRED'
    );
  });
};

instance.interceptors.request.use((config) => {
  //   const language = i18n.language;
  //   config.headers['X-localization'] = language ? language : 'pl';
  const newConfig = {
    ...config,
  };
  if (newConfig.withTwoFactor && newConfig.withPermissions.length > 0) {
    return prepare2FAPromise(newConfig);
  }
  return newConfig;
});

instance.interceptors.response.use(
  (response) => {
    if (response.config.twoFactorSigned) {
      instance.closeTwoFactor();
    }
    return response;
  },
  (error) => {
    /*
     * Check if failed request failed due to 2FA
     * */
    const is2faError = isTwoFactorError(
      error.response.data.errors,
      error.config,
      error.response.status
    );
    if (is2faError) {
      /*
       * create proxy promise
       * */
      return new Promise((resolve, reject) => {
        /*
         * prepare callbacks to resolve this promise
         * callbacks will be stored in App.js
         * */
        const successCallback = (response) => {
          resolve(response);
          instance.closeTwoFactor();
        };
        const errorCallback = (errorResponse) => {
          reject(errorResponse);
          instance.closeTwoFactor();
        };
        /*
         * call callback from App.js to initialize 2FA modal and pass data to store
         * */
        instance.onTwoFactorCallback(
          error.config, // original request config
          successCallback, // callback when 2FA is resolved successfully
          errorCallback, // callback when user failed to authorize request with 2FA
          'repeat',
          is2faError
        );
      });
    }
    /*
     * Request failed because of other reasons than 2FA, pass error normally
     * */
    return Promise.reject(error);
  }
);

export default instance;
