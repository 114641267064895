import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TwoFactorForm from '../../TwoFactorModal/subcomponents/TwoFactorForm';
import registerActions from '../../../redux/actions/register.actions';
import { REQUEST_STATUS } from '../../../models/common.model';

const PhoneConfirmForm = (props) => {
  const { activationCode } = props;

  const confirmPhoneStatus = useSelector((state) => state.register.confirmPhoneStatus);
  const registerSmsLastTimestamp = useSelector((state) => state.register.registerSmsLastTimestamp);
  const reSendActivationSmsStatus = useSelector(
    (state) => state.register.reSendActivationSmsStatus
  );
  const [hasErrors, setHasErrors] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (confirmPhoneStatus === REQUEST_STATUS.ERROR) {
      setHasErrors(true);
    }
  }, [confirmPhoneStatus]);

  const handleSetCode = (smsCode) => {
    dispatch(registerActions.confirmPhoneNumber(activationCode, smsCode));
  };

  const handleReSend = () => {
    dispatch(registerActions.reSendPhoneConfirmationSms(activationCode));
  };

  return (
    <TwoFactorForm
      method="sms"
      hasErrors={hasErrors}
      onSend={(smsCode) => {
        handleSetCode(smsCode);
      }}
      onReSend={() => {
        handleReSend();
      }}
      lastSendTimestamp={registerSmsLastTimestamp}
      isReSending={reSendActivationSmsStatus === REQUEST_STATUS.PENDING}
      isSending={confirmPhoneStatus === REQUEST_STATUS.PENDING}
      onClearErrors={() => {
        setHasErrors(false);
      }}
    />
  );
};

export default PhoneConfirmForm;
