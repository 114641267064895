import React from 'react';
// import redirectHelper from '../../helpers/redirectHelper';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import identityActions from '../../redux/actions/identity.actions';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LoginOrRegisterButtons from '../../components/LoginOrRegisterButtons/LoginOrRegisterButtons';
import redirectHelper from '../../helpers/redirectHelper';
import authActions from '../../redux/actions/auth.actions';
import cookieHelper from '../../helpers/cookieHelper';
import alertsActions from '../../redux/actions/alerts.actions';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { REQUEST_STATUS } from '../../models/common.model';

class JoinWorkspacePage extends React.Component {
  componentDidMount() {
    const { match, history, getInvitationStatus, checkTokenStatus, errorAlert, t } = this.props;
    const { inviteId } = match.params;
    if (!inviteId) {
      errorAlert(t('inviteWorkspacePage.inviteNotFound'));
      history.push(`/${i18n.language}`);
    } else {
      getInvitationStatus(inviteId);
      checkTokenStatus(['AUTH_USER_GET_PROFILE']);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      invitationDetailStatus,
      acceptInvitationStatus,
      joinedWorkspaceIdentityId,
      registerIntoWorkspaceStatus,
      registerConfirmationToken,
      t,
      registerIntoWorkspaceErrors,
      errorAlert,
    } = this.props;

    if (
      prevProps.invitationDetailStatus === REQUEST_STATUS.PENDING &&
      invitationDetailStatus === REQUEST_STATUS.ERROR
    ) {
      errorAlert(t('inviteWorkspacePage.inviteNotFound'));
      history.push(`/${i18n.language}`);
    }

    if (
      prevProps.acceptInvitationStatus === REQUEST_STATUS.PENDING &&
      acceptInvitationStatus === REQUEST_STATUS.SUCCESS
    ) {
      cookieHelper.setCookie('activeIdentity', joinedWorkspaceIdentityId.identityId);
      redirectHelper.setRedirect(process.env.REACT_APP_APP_URL);
      redirectHelper.redirect();
    }

    if (prevProps.registerIntoWorkspaceStatus === REQUEST_STATUS.PENDING) {
      if (registerIntoWorkspaceStatus === REQUEST_STATUS.ERROR) {
        const firstCode = registerIntoWorkspaceErrors[0]?.code || null;
        switch (firstCode) {
          case 'NOT_FOUND':
            errorAlert(t('inviteWorkspacePage.inviteNotFound'));
            history.push(`/${i18n.language}`);
            break;
          case 'INTERNAL_ERROR':
          default:
            errorAlert(t('inviteWorkspacePage.internalError'));
            break;
        }
      } else if (registerIntoWorkspaceStatus === REQUEST_STATUS.SUCCESS) {
        history.push(`/${i18n.language}/${t('urls.activate')}/${registerConfirmationToken}`);
      }
    }
  }

  onLogin() {
    const { history } = this.props;
    redirectHelper.setRedirect(window.location.pathname);
    history.push(`/${i18n.language}`);
  }

  onJoin() {
    const { joinWorkspace, match } = this.props;
    const { inviteId } = match.params;
    joinWorkspace(inviteId);
  }

  onRegister() {
    const { match, registerIntoWorkspace } = this.props;
    const { inviteId } = match.params;
    const { language } = i18n;

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA, { action: 'submit' })
        .then((token) => {
          registerIntoWorkspace(inviteId, language, token);
        });
    });
  }

  render() {
    const {
      invitationDetails,
      invitationDetailStatus,
      t,
      tokenStatus,
      acceptInvitationStatus,
    } = this.props;

    return (
      <>
        <Container className="d-flex flex-column h-100">
          <Row className="pl-3 pr-3">
            <Col>
              <Header redirectType="register" noRedirect />
            </Col>
          </Row>
          <Row className="p-3 flex-fill align-items-center join-workspace-page">
            {invitationDetailStatus === REQUEST_STATUS.SUCCESS && (
              <Col>
                <div className="d-flex justify-content-center mb-4">
                  <UserAvatar size="extraLarge">{invitationDetails.name}</UserAvatar>
                </div>
                <h2 className="text-center text-color-primary mb-2 mb-sm-6">
                  {invitationDetails.name}
                </h2>
                <h3 className="text-center mb-2 mb-sm-6">{t('inviteWorkspacePage.invitedTo')}</h3>
                <div className="d-flex justify-content-center">
                  <LoginOrRegisterButtons
                    onLogin={() => {
                      this.onLogin();
                    }}
                    onRegister={() => {
                      this.onRegister();
                    }}
                    isLoggedIn={tokenStatus === REQUEST_STATUS.SUCCESS}
                    loggedLabel={t('inviteWorkspacePage.joinWorkspace')}
                    isDisabled={
                      acceptInvitationStatus === REQUEST_STATUS.PENDING ||
                      acceptInvitationStatus === REQUEST_STATUS.SUCCESS
                    }
                    onLoggedAction={() => {
                      this.onJoin();
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row className="pl-3 pr-3">
            <Col>
              <Footer />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const actionCreators = {
  getInvitationStatus: identityActions.getInvitationDetails,
  checkTokenStatus: authActions.checkTokenStatus,
  joinWorkspace: identityActions.joinWorkspace,
  registerIntoWorkspace: identityActions.registerIntoWorkspace,
  successAlert: alertsActions.success,
  errorAlert: alertsActions.error,
};

function mapState(state) {
  const {
    invitationDetailStatus,
    invitationDetails,
    acceptInvitationStatus,
    joinedWorkspaceIdentityId,
    registerIntoWorkspaceStatus,
    registerConfirmationToken,
    registerIntoWorkspaceErrors,
  } = state.identity;
  const { tokenStatus } = state.auth;
  return {
    invitationDetailStatus,
    invitationDetails,
    tokenStatus,
    acceptInvitationStatus,
    joinedWorkspaceIdentityId,
    registerIntoWorkspaceStatus,
    registerConfirmationToken,
    registerIntoWorkspaceErrors,
  };
}

const translatedJoinWorkspacePage = withTranslation()(JoinWorkspacePage);
const connectedJoinWorkspacePage = connect(mapState, actionCreators)(translatedJoinWorkspacePage);
export default connectedJoinWorkspacePage;
