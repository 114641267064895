import React, { useState, useEffect, useRef, useCallback } from 'react';
import { findDOMNode } from 'react-dom';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import parsePhoneNumber from 'libphonenumber-js/mobile';
import PropTypes from 'prop-types';
import registerActions from '../../../redux/actions/register.actions';
import HelpInfo from '../../HelpInfo/HelpInfo';
import alertsActions from '../../../redux/actions/alerts.actions';
import { REQUEST_STATUS } from '../../../models/common.model';

const PhoneVerificationForm = (props) => {
  const { activationCode } = props;
  const { i18n, t } = useTranslation();
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();
  const [phone, setPhone] = useState('');
  const [phoneCountry, setPhoneCountry] = useState();
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [initialCountry, setInitialCountry] = useState('');
  const [closeVisible, setCloseVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const prevPhoneStatus = useRef(REQUEST_STATUS.IDLE);
  const { setPhoneNumberStatus, setPhoneNumberError } = useSelector((state) => state.register);
  let submitButtonRef = useRef(null);
  const { language } = i18n;

  useEffect(() => {
    if (language === 'en') {
      setInitialCountry('gb');
    } else {
      setInitialCountry(language);
    }
  }, [language]);

  useEffect(() => {
    if (
      prevPhoneStatus.current === REQUEST_STATUS.PENDING &&
      setPhoneNumberStatus === REQUEST_STATUS.ERROR
    ) {
      if (setPhoneNumberError?.errors?.length > 0) {
        switch (setPhoneNumberError.errors[0].code) {
          case 'PHONE_NUMBER_REGEX_NO_MATCH':
            setAlertVisible(true);
            break;
          case 'INVALID_PHONE_NUMBER':
            setAlertVisible(true);
            break;
          default:
            dispatch(alertsActions.error(t('phoneActivationScreen.incorrectNumber')));
            break;
        }
      } else {
        dispatch(alertsActions.error(t('common.errors.unknownError')));
      }
    }
    prevPhoneStatus.current = setPhoneNumberStatus;
  }, [setPhoneNumberStatus, setPhoneNumberError, dispatch, t]);

  const handleCountryDropdownOpen = useCallback((event) => {
    const dropdown = document.getElementsByClassName('flag-dropdown')[0];
    setTimeout(() => {
      const dropdownInputCollection = findDOMNode(dropdown).getElementsByClassName('search-box');
      const dropdownInput = [...dropdownInputCollection];
      if (dropdownInput) {
        dropdownInput.forEach((val) => {
          val.setAttribute('spellCheck', false);
        });
      }
    }, 1000);
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleCountryDropdownOpen);
    return () => {
      window.removeEventListener('click', handleCountryDropdownOpen);
    };
  }, [handleCountryDropdownOpen]);

  useEffect(() => {
    const dropdown = document.getElementsByClassName('flag-dropdown')[0];
    dropdown.addEventListener(
      'click',
      () => {
        if (!closeVisible || dropdown.classList.contains('open')) {
          setCloseVisible(true);
        }
      },
      { once: true }
    );
  }, [closeVisible]);

  const splitPrefixFromPhone = () => {
    return {
      phoneNumber: phone.substr(phoneCountry.dialCode.length + 1),
      prefix: phoneCountry.dialCode,
    };
  };

  const validatePhoneNumber = (value, country) => {
    const input = document.getElementsByClassName('form-control')[0];
    let isValid = true;
    if (phone.length > 0) {
      const phoneNumber = parsePhoneNumber(value, country.iso2.toUpperCase());
      if (phoneNumber) {
        isValid = phoneNumber.isValid();
        if (isValid) {
          input.classList.add('valid-number');
        }
      }
    }
    return isValid;
  };

  const handleSubmitClick = () => {
    submitButtonRef.blur();
    setFormSubmitted(true);
    if (!isPhoneValid) {
      setAlertVisible(true);
    } else {
      const phoneData = splitPrefixFromPhone();
      dispatch(
        registerActions.addPhoneNumber(activationCode, phoneData.prefix, phoneData.phoneNumber)
      );
    }
  };

  const handleInputChange = (value, country) => {
    setCloseVisible(false);
    setPhone(value);
    setPhoneCountry(country);
    setAlertVisible(false);
    const phoneNumber = parsePhoneNumber(value, country.countryCode.toUpperCase());
    if (phoneNumber && phoneNumber.isValid()) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmitClick();
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <h1 className={clsx(!isDesktopVersion && 'h3 text-center')}>
          {t('phoneActivationScreen.header')}
        </h1>
        {closeVisible && (
          <div className="test-close">
            <Button onKeyDown={handleKeyDown} variant="text" onClick={() => setCloseVisible(false)}>
              <i className="fico-doxy-close" />
            </Button>
          </div>
        )}
        {formSubmitted && alertVisible && (
          <div className="mb-6">
            <Alert
              className="d-flex w-100 justify-content-between align-items-center"
              variant="danger"
            >
              {t('phoneActivationScreen.incorrectNumber')}
              <Button
                className="m-0 p-0"
                variant="text"
                onClick={() => {
                  setAlertVisible(false);
                }}
              >
                <i className="fico-doxy-close" />
              </Button>
            </Alert>
          </div>
        )}
        <PhoneInput
          searchPlaceholder={t('phoneActivationScreen.searchPlaceholder')}
          searchNotFound={t('phoneActivationScreen.emptyResults')}
          placeholder={t('phoneActivationScreen.inputPlaceholder')}
          country={initialCountry}
          value={phone}
          onFocus={() => setCloseVisible(false)}
          onChange={(val, country) => handleInputChange(`+${val}`, country)}
          enableSearch
          disableSearchIcon
          copyNumbersOnly={false}
          isValid={(val, country) => validatePhoneNumber(`+${val}`, country)}
          onKeyDown={handleKeyDown}
        />
      </Col>
      <Col xs={12}>
        <Button
          type="button"
          variant="primary"
          className="w-100 mt-7"
          onClick={handleSubmitClick}
          ref={(buttonRefDOM) => {
            submitButtonRef = buttonRefDOM;
          }}
        >
          {t('phoneActivationScreen.button')}
        </Button>
      </Col>
      <Col xs={12} className="mt-7">
        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row ">
          <HelpInfo label={t('phoneActivationScreen.infoText')} linkLabel={t('support.link')} />
        </div>
      </Col>
      <Col xs={12} className="mt-7">
        <p className="text text-center text-body-small text-color-light">
          {t('phoneActivationScreen.securityInfo')}
        </p>
      </Col>
    </Row>
  );
};

PhoneVerificationForm.propTypes = {
  activationCode: PropTypes.string,
};

export default PhoneVerificationForm;
