import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import registerActions from '../../redux/actions/register.actions';
import passwordResetActions from '../../redux/actions/passwordReset.actions';
import PasswordInput from '../PasswordInput/PasswordInput';
import HelpInfo from '../HelpInfo/HelpInfo';

const PasswordForm = (props) => {
  const { type, activationCode } = props;
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  let submitButtonRef = useRef(null);
  const firstRender = useRef(true);

  const validateForm = useCallback(() => {
    let isValid = true;
    if (password.length === 0) {
      isValid = false;
    }
    if (password !== repeatedPassword) {
      isValid = false;
    }
    if (password.length < 8) {
      isValid = false;
    }
    return isValid;
  }, [password, repeatedPassword]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (formSubmitted) {
      validateForm();
    }
  }, [validateForm, formSubmitted]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    submitButtonRef.blur();
    setFormSubmitted(true);

    if (validateForm()) {
      if (type === 'createPasswordScreen') {
        dispatch(registerActions.setPassword(activationCode, password));
      } else if (type === 'passwordRestartPage') {
        dispatch(passwordResetActions.resetPassword(activationCode, password));
      }
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <h1 className={clsx('text-center text-md-left', !isDesktopVersion && 'h2')}>
          {t(`${type}.header`)}
        </h1>
      </Col>
      <Col>
        <Form onSubmit={onFormSubmit}>
          <Form.Group controlId="formPassword">
            <PasswordInput
              isInvalid={formSubmitted && password.length < 8}
              placeholder={t(`${type}.passwordPlaceholder`)}
              value={password}
              onChange={(value) => setPassword(value)}
              feedback={t(`${type}.passwordRequired`)}
              withMeter
            />
          </Form.Group>
          <Form.Group controlId="formRepeatedPassword">
            <PasswordInput
              isInvalid={formSubmitted && password !== repeatedPassword}
              placeholder={t(`${type}.repeatedPasswordPlaceholder`)}
              value={repeatedPassword}
              onChange={(value) => setRepeatedPassword(value)}
              feedback={t(`${type}.passwordMismatch`)}
            />
          </Form.Group>
          <Button
            className="mt-5 w-100 btn-medium shadow-none"
            variant="primary"
            type="submit"
            ref={(buttonRefDOM) => {
              submitButtonRef = buttonRefDOM;
            }}
          >
            {t(`${type}.button`)}
          </Button>
        </Form>
      </Col>
      <Col xs={12} className="mt-7">
        <HelpInfo label={t('support.info')} linkLabel={t('support.link')} />
      </Col>
    </Row>
  );
};

PasswordForm.propTypes = {
  type: PropTypes.string,
  activationCode: PropTypes.string,
};

export default PasswordForm;
