import axios from '../../helpers/axios';

const identityService = {
  getInvitationStatus,
  joinWorkspace,
  registerIntoWorkspace,
};

function getInvitationStatus(token) {
  return axios
    .get(`/identity-service/invitations/${token}/details`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function joinWorkspace(token) {
  return axios
    .post(`/identity-service/invitations/${token}/accept`, undefined, {
      withCredentials: true,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function registerIntoWorkspace(token, language, captchaToken) {
  return axios.post(
    `/identity-service/invitations/${token}/create-account`,
    {
      language: language.toUpperCase(),
      agreements: ['BASIC_AGREEMENT'],
    },
    {
      headers: {
        Captcha: captchaToken,
      },
    }
  );
}

export default identityService;
