import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import Header from '../../components/Header/Header';
import LoginOrRegisterButtons from '../../components/LoginOrRegisterButtons/LoginOrRegisterButtons';
import Footer from '../../components/Footer/Footer';
import authActions from '../../redux/actions/auth.actions';
import documentActions from '../../redux/actions/document.actions';
import filesModuleActions from '../../redux/actions/filesModule.actions';
import UserAvatarAndTextItem from '../../components/UserAvatarAndTextItem/UserAvatarAndTextItem';
import redirectHelper from '../../helpers/redirectHelper';
import ConfirmationScreen from '../../components/ConfirmationScreen/ConfirmationScreen';
import Loader from '../../components/Loader/Loader';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { documentDSLModule, documentModule } from '../../redux/services/document.service';
import { REQUEST_STATUS } from '../../models/common.model';
import clsx from 'clsx';

const DocumentInvitePage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCertificate =
    history.location.pathname.includes('certificate') ||
    history.location.pathname.includes('certyfikat');
  const redirectPath = isCertificate ? 'join-to-certificate' : 'join-to-document';
  const apiService = isCertificate ? documentDSLModule : documentModule;
  const { tokenStatus } = useSelector((state) => state.auth, undefined);
  const { documentInvitationDetails, getDocumentInvitationStatus } = useSelector(
    (state) => state.document,
    undefined
  );
  const [isPreviewFull, setIsPreviewFull] = useState(false);
  const { getFileStatus, fileUrl } = useSelector((state) => state.filesModule, undefined);
  const { search } = useLocation();
  const pinCode = new URLSearchParams(search).get('pin');
  const pinCodeUrl = pinCode ? `?pin=${pinCode}` : '';
  const { inviteId } = match.params;

  useEffect(() => {
    if (!inviteId) {
      history.push(`/${i18n.language}`);
    } else {
      dispatch(documentActions.getDocumentInvitationDetails(inviteId, apiService));
      dispatch(authActions.checkTokenStatus(['AUTH_USER_GET_PROFILE']));
    }
  }, [history, inviteId, dispatch, apiService]);

  useEffect(() => {
    if (getDocumentInvitationStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(filesModuleActions.getFile(documentInvitationDetails.imageDownloadToken));
    }
  }, [getDocumentInvitationStatus, documentInvitationDetails, dispatch]);

  useEffect(() => {
    if (
      (tokenStatus === REQUEST_STATUS.SUCCESS &&
        getDocumentInvitationStatus === REQUEST_STATUS.SUCCESS) ||
      (getDocumentInvitationStatus === REQUEST_STATUS.SUCCESS &&
        tokenStatus === REQUEST_STATUS.SUCCESS)
    ) {
      window.location.href = `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`;
    }
  }, [pinCodeUrl, tokenStatus, getDocumentInvitationStatus, inviteId, redirectPath]);

  const onLogin = () => {
    redirectHelper.setRedirect(
      `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
    );
    history.push(`/${i18n.language}`);
  };

  const onRegister = () => {
    redirectHelper.setRedirect(
      `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
    );
    history.push(`/${i18n.language}/${t('urls.register')}`);
  };

  const togglePreview = () => setIsPreviewFull(!isPreviewFull);

  const imageContent = (inModal) => (
    <div
      onClick={inModal ? () => undefined : togglePreview}
      className={clsx(
        'invite-document-image-wrapper',
        isCertificate && !inModal && 'clickable',
        inModal && 'invite-document-image-wrapper--modal'
      )}
    >
      {fileUrl && getFileStatus === REQUEST_STATUS.SUCCESS ? (
        <img src={fileUrl} alt={documentInvitationDetails.documentName} />
      ) : (
        <Loader />
      )}
    </div>
  );

  return (
    <>
      <Container className="d-flex flex-column h-100" id="document-invite-page">
        <Row>
          <Col>
            <Header redirectType="register" noRedirect />
          </Col>
        </Row>
        <Row className="flex-fill align-items-center">
          {getDocumentInvitationStatus === REQUEST_STATUS.IDLE ||
          getDocumentInvitationStatus === REQUEST_STATUS.PENDING ? (
            <Loader />
          ) : (
            <>
              {getDocumentInvitationStatus === REQUEST_STATUS.ERROR ? (
                <Col>
                  <ConfirmationScreen
                    imgSrc="/images/error.svg"
                    header={t('documentInvitePage.fetchInvitationError')}
                  />
                </Col>
              ) : (
                <Col>
                  <div className="content-wrapper">
                    <Row
                      className="p-3 flex-fill align-items-center justify-content-center"
                      noGutters
                    >
                      <Col>
                        <Row className="d-flex">
                          <Col xs={12} md className="documentName">
                            <strong className="text-body-small text-color-primary label">
                              {t('documentInvitePage.documentName')}
                            </strong>
                            <h2>{documentInvitationDetails.documentName}</h2>
                          </Col>
                          <Col xs={12} md="auto">
                            <strong className="text-body-small text-color-primary label">
                              {t('documentInvitePage.author')}
                            </strong>
                            <UserAvatarAndTextItem
                              classes=""
                              tooltip={documentInvitationDetails.inviterName}
                              size="medium"
                              alt={documentInvitationDetails.inviterName}
                              badgeChildren={documentInvitationDetails.workspaceName}
                              badgeSize="mini"
                              text={documentInvitationDetails.inviterName}
                              additionalText={documentInvitationDetails.inviterName}
                              description={
                                documentInvitationDetails.inviterName ===
                                documentInvitationDetails.workspaceName
                                  ? ''
                                  : documentInvitationDetails.workspaceName
                              }
                            />
                          </Col>
                        </Row>
                        {imageContent(false)}
                        <div className="d-flex justify-content-center">
                          <LoginOrRegisterButtons
                            onLogin={() => {
                              onLogin();
                            }}
                            onRegister={() => {
                              onRegister();
                            }}
                            isLoggedIn={tokenStatus === REQUEST_STATUS.SUCCESS}
                            loggedLabel={t('documentInvitePage.signDocument')}
                            isDisabled={false}
                            withTerms={false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
      <Modal show={isPreviewFull} onHide={togglePreview} centered size="xl">
        {imageContent(true)}
      </Modal>
    </>
  );
};

export default DocumentInvitePage;
