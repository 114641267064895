const links = (language) => {
  return {
    privacyPolicy:
      language === 'pl'
        ? 'https://doxychain.com/regulaminy/polityka-prywatnosci-doxychain.pdf'
        : 'https://doxychain.com/terms-and-conditions/privacy-policy-doxychain.pdf',
    termsOfService:
      language === 'pl'
        ? 'https://doxychain.com/regulaminy/regulamin-doxychain.pdf'
        : 'https://doxychain.com/terms-and-conditions/terms-and-conditions-doxychain.pdf',
    helpDesk: 'https://docs.doxychain.com/docs/help-docs/',
  };
};

export default links;
